@media (max-width:800px) {
  img#dimage {
    width: 431px;
    height: 117px;
  }
}

@media (max-width:400px) {
  img#dimage {
    width: 287px;
    height: 78px;
  }
}