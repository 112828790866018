@media (max-width:800px) {
  img#dimage {
    width: 300px;
  }
}

@media (max-width:400px) {
  img#dimage {
    width: 250px;
  }
}